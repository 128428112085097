<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column align-center">
              <eden-image-upload :image-url.sync="form.avatar" :image-folder="'customers'" />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="Company Details" subtitle="Let’s get to know this customer">
        <template slot="form-fields">

          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Company name" prop="company_name" :rules="validateField()">
                <el-input type="text" v-model="form.company_name" placeholder="Enter company name"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :md="24">
              <el-form-item label="Company email" prop="email" :rules="validateEmail()">
                <el-input type="text" v-model="form.email" placeholder="you@mail.com"
                  :disabled="action === 'edit'"></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Country" prop="country_id" :rules="validateField()">
                <el-select v-model="form.country_id" placeholder="Select a customer country" @change="getContents">
                  <el-option v-for="(counttry, i) in countries" :key="i" :label="formatText(counttry.name)"
                    :value="counttry.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="State" prop="state_id" :rules="validateField()">
                <el-select v-model="form.state_id" placeholder="Select a customer state">
                  <el-option v-for="(state, i) in states" :key="i" :label="formatText(state.name)" :value="state.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Address" prop="company_address" :rules="validateField()">
                <el-input type="text" v-model="form.company_address" placeholder="Company address"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Location area" prop="location_area_id" :rules="validateField()">
                <el-select v-model="form.location_area_id" placeholder="Select a customer role">
                  <el-option v-for="(area, i) in locations" :key="i" :label="formatText(area.name)" :value="area.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="Contact Person" subtitle="Select the services this customer will be getting.">
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item label="First name" prop="contact_person_first_name" :rules="validateName()">
                <el-input type="text" v-model="form.contact_person_first_name" placeholder="Enter first name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="Last name" prop="contact_person_last_name" :rules="validateName()">
                <el-input type="text" v-model="form.contact_person_last_name" placeholder="Enter last name"></el-input>
              </el-form-item>
            </el-col>

            <el-col :md="24">
              <el-form-item label="Email" prop="contact_person_email_address" :rules="validateField()">
                <el-input type="text" v-model="form.contact_person_email_address" placeholder="you@mail.com"
                  :disabled="action === 'edit'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Phone number" prop="contact_person_phone_number"
                :rules="validatePhone('Phone number')">
                <eden-phone-input :input.sync="form.contact_person_phone_number" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="is-flex-wrap" v-if="false">
            <el-col :md="24">
              <el-form-item label="password" prop="password" :rules="validateField()">
                <el-input type="password" v-model="form.password" placeholder="password"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" class="is-flex-wrap">
            <el-col :md="24">
              <el-form-item label="Role" prop="company_role" :rules="validateField()">
                <el-select v-model="form.company_role" placeholder="Select a customer role">
                  <el-option v-for="(role, i) in allRoles" :key="i" :label="formatText(role)" :value="role" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="$router.go(-1)">Cancel</el-button>
            <el-button v-if="action === 'add'" type="primary" :loading="adding" @click="add">Add Customer
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update">Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>
  
<script>
import { ALL_ROLES } from "@/constants/constants";
import business from "@/requests/business";
import businessRequest from "@/requests/business";
import individual from "@/requests/customers/individual";

export default {
  name: "CustomerBusinessForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        avatar: this.getDefaultAvatarUrl(),
        contact_person_first_name: "",
        contact_person_last_name: "",
        contact_person_email_address: "",
        contact_person_phone_number: "",
        company_address: "",
        location_area_id: "",
        customer_persona: "",
        company_role: "",
        password: 'eden'
      },
      states: [],
      personas: [],
      countries: [],
      locationAreas: [],
      uploadingPhoto: false,
      adding: false,
      updating: false,
      allRoles: ALL_ROLES,
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    countryCode() {
      return this.$store.getters.location;
    },
    locations() {
      return this.$store.getters.location_areas_list;
    },
    location() {
      return this.$store.getters.location;
    },
    services() {
      return ["Cleaning", "Laundry", "Meal"];
    },
    gardeners() {
      const gardeners = [...new Set(this.$store.getters.gardeners_list)];
      return this.sortList(gardeners, "name");
    },
    timePastDeadline() {
      if (this.allowAccessFor(["gardener", "gardener_pro"], true)) {
        var today = new Date();
        today.setHours(17);
        today.setMinutes(30);

        return (
          this.getDateDifference({
            fDate: new Date().toISOString(),
            sDate: today.toISOString(),
            unit: "second",
          }) > 0
        );
      }

      return false;
    },
  },
  watch: {
    loading() {
      if (this.action === "edit" && !this.loading) {
        this.form = this.customer;
      }
    },

    account() {
      if (this.action === "edit") {
        this.formattContent()
      }
    }
  },
  created() {
    this.getCountries()
  },
  mounted(){
    if (this.action === "edit") {
        this.formattContent()
      }
  },
  methods: {
    getPersonas() {
      individual
        .personas()
        .then((response) => {
          if (response.data.status) {
            this.personas = response.data.data;
          }
        })
        .catch();
    },

    formattContent() {
      let [first_name, last_name] = this.account.admins[0].name.split(" ")
      this.form = {
        avatar: this.getDefaultAvatarUrl(),
        country_id: this.account.country_id,
        state_id: this.account.state_id,
        company_name: this.account.name,
        contact_person_first_name: first_name,
        contact_person_last_name: last_name,
        contact_person_email_address: this.account.admins[0].email,
        contact_person_phone_number: this.formatPhoneNumber(this.account.admins[0].phone_number),
        company_address: this.account.address,
        location_area_id: this.account.location_area.id,
        company_role: this.account.admins[0].company_role
      }
    },

    formatPhoneNumber(number) {
      if (this.location === "NG") {
        if (number.startsWith('234')) return number.replace('234', '')
        if (number.startsWith('+234')) return number.replace('+234', '')
        if (number.startsWith('0')) return number.replace('0', '')
      }
      return number
    },

    getCountries() {
      businessRequest.getCountries().then((response) => {
        this.countries = response.data.data;
        this.getStates()
      })
    },

    getContents(countryId) {
      this.getStates(countryId)
      this.getLocationAreas(countryId)
    },
    getStates() {
      let countryId = this.countries.find((elm) => elm.code == this.countryCode)?.id
      businessRequest.getStates(countryId).then((response) => {
        this.states = response.data.data;
      })
    },
    getLocationAreas(countryId) {
      let countryCode = this.countries.find((elm) => elm.id == countryId)?.code
      businessRequest.getLocationAreas(countryCode).then((response) => {
        this.locationAreas = response.data.data;
      })
    },
    setSubscriptionConfiguration({ config, amount }) {
      this.form.services = Object.keys(config);
      this.form.plan = config;
      this.form.amount = amount;
    },
    disabledDates(time) {
      if (this.allowAccessFor([])) {
        return false;
      }
      return (
        time.getTime() < new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      );
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.adding = true;
        const payload = {
          ...this.form
        };

        business
          .createAccount(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.adding = false;
              this.$router.push({ name: "business.index" });
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;

        let payload = { ...this.form };

        // payload.full_name = payload.first_name + " " + payload.last_name;
        // payload.home_address = payload.address;
        // payload.phone_number = payload.phoneNo;
        // payload.avatar = payload.avatar || this.getAvatar();

        business
          .update(this.customerId, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.$router.push({
                name: "business.profile",
                params: { id: this.customerId },
              });
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
  },
};
</script>
  
<style scoped lang="scss"></style>
  