<template>
  <div>
    <eden-page-header :title="'Customers'" :subtitle="`Business - ${$route.params.id ? 'Edit':'Add new'}  account`" />
    <p v-if="loading">Loading...</p>
    <add-business-form v-else :action="$route.params.id ? 'edit' : 'add'" :account="account" />
  </div>
</template>
  
<script>
import businessRequest from "../../requests/business/index";
import AddBusinessForm from "@/components/Business/AddBusinessForm.vue";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerIndividualAdd",
  components: {
    AddBusinessForm,
  },
  data() {
    return {
      account: {},
      loading: false,
    };

  },
  created() {
    this.$store.dispatch(actions.GET_GARDENERS_LIST);
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
    if (this.$route.params.id) {
      this.getBusinessAccount()
    }
  },

  methods: {
    async getBusinessAccount() {
      this.loading = true
      businessRequest.get(this.$route.params.id).then((response) => {
        const { data } = response.data
        this.account = data
        this.loading = false
      }).catch((error) => {
        this.loading = false
        const errorMessage = error.response.data;
        if (errorMessage.errors) {
          const errorKeys = Object.keys(errorMessage.errors);
          this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
        } else {
          this.$message.error(errorMessage.message);
        }
      })
    }
  }
};
</script>
  
<style scoped lang="scss"></style>
  