export const ALL_ROLES = [
    "Accountant",
    "Administrative officer",
    "Architect",
    "Analyst",
    "Artist",
    "Baker",
    "Banker",
    "Beautician",
    "Broadcaster",
    "Career Adviser",
    "Chief Executive Officer",
    "Chief Financial Officer",
    "Chief Marketing Officer",
    "Chief Technical Officer",
    "Clerk",
    "Community Manager",
    "Country Manager",
    "Customer Service Representative",
    "Data Analyst",
    "Database Administrator",
    "Dentist",
    "Digital Marketer",
    "Doctor",
    "Engineer",
    "Events Manager",
    "Financial Adviser",
    "Food Scientist",
    "Graphic Designer",
    "Gym Instructor",
    "Happiness Engineer",
    "Health and Safety Inspector",
    "Human Resource Officer/Manager",
    "Interpreter",
    "Investment Banker",
    "Journalist",
    "Kitchen Assistant",
    "Legal Practitioner",
    "Lecture",
    "Logistics Manager",
    "Management Consultant",
    "Market Research Executive",
    "Marketing Manager",
    "Nurse",
    "Office Manager",
    "People Operations Manager",
    "Personal Assistant",
    "Photographer",
    "Procurement Manager",
    "Product Designer",
    "Product Manager",
    "Project Manager",
    "Public Relations Officer",
    "Reporter",
    "Researcher",
    "Social Worker",
    "Surveyor",
    "Systems Analyst",
    "Teacher",
    "Tour Guide",
    "Travel Agency Manager",
    "TV or Radio Presenter",
    "UX Designer",
    "Venture Capitalist",
    "Waiter",
    "Web Developer",
    "Wellness Curator",
    "Writer",
    "Zoologist",
  ]